@function color($key) {
  @if map-has-key($colors, $key) {
    @return map-get($colors, $key);
  } //   @warn "Unknown `#{$key}` in $colors.";
  //   @return null;
}

@mixin box-shadow($horz: 0, $vert: 5px, $blurRad: 10px, $spreadRad: 1px, $a:0.2) {
  -webkit-box-shadow: $horz $vert $blurRad $spreadRad rgba(0, 0, 0, $a) !important;
  -moz-box-shadow: $horz $vert $blurRad $spreadRad rgba(0, 0, 0, $a) !important;
  box-shadow: $horz $vert $blurRad $spreadRad rgba(0, 0, 0, $a) !important;
}

@mixin rounded($radius: 0.5em) {
  -webkit-border-radius: 0.5em;
  -moz-border-radius: 0.5em;
  border-radius: 0.5em;
}

@mixin centerImage($top, $transform: -50%) {
  position: relative;
  top: $top;
  transform: translateY($transform);
}

@mixin centerContent() {
  margin-left: auto !important;
  margin-right: auto !important;
}

@mixin imageMask($imageHeight, $transform: -50%, $top:50%) {
  height: $imageHeight;
  overflow: hidden;
  img {
    @include centerImage($top, $transform);
    width: 100%;
    z-index: 0;
  }
}
