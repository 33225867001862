@font-face {
  font-family: LucidaSans;
  src: url(assets/fonts/lucida-sans/LSANSD.TTF) format('truetype');
}

@font-face {
  font-family: LucidaSansNormal;
  src: url(assets/fonts/lucida-sans/LSANS.TTF) format('truetype');
}

